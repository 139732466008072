import { CallbackFunction } from './callback.function';
import { InjectionToken, Injectable } from '@angular/core';

export interface RequestParameters<T> {
  clb?: CallbackFunction<T>;
  error?: CallbackFunction<any>;
  complete?: () => void;
}

export interface RequestIdentityParameters<T> extends RequestParameters<T> {
  id: string | number;
}

export interface PaginateParameters<T> extends RequestParameters<T> {
  page?: number;
  limit?: number;
  orderBy?: string;
  orderDirection?: string;
  timespan?: Record<string, unknown>;
  additionalOrderBy?: string;
  additionalOrderByDirection?: string;
}

export interface NewProductFilters {
  categoryIds?: any[];
  supplierIds?: any[];
  storageIds?: any[];
  noProductGroupsAssigned: boolean;
}

export interface SearchParameters<T> extends PaginateParameters<T> {
  search?: string;
  filters?: string[];
  searchView?: unknown;
}
export interface SearchService {
  search<T>(searchParameters: SearchParameters<T>): unknown;
  getSearchResultRoute(): string;
}

@Injectable()
export class SearchServiceMock implements SearchService {
  search<T>(searchParameters: SearchParameters<T>) {}

  getSearchResultRoute(): string {
    throw new Error('SearchServiceMock does NOT implement that');
  }
}

export const SEARCH_SERVICE_TOKEN = new InjectionToken<SearchService>('App.Shared.SearchService');
